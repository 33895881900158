import db from "./FIREBASE_CONFIG";
import React,{useState,useEffect} from 'react';
import { doc, getDoc } from "firebase/firestore";
import { collection, getDocs } from "firebase/firestore";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import html2pdf from "html2pdf.js/src";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableFooter from '@mui/material/TableFooter';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import GeneratePDF from "./GeneratePDF";
import {PDFDownloadLink} from "@react-pdf/renderer";
import CircularProgress from '@mui/material/CircularProgress';


const theme = createTheme();


function GetReceipt() {
    const [store, setStore] = useState([])
    const [products, setProducts] = useState([])
    const [standardTaxTotal, setStandardTaxTotal] = useState(0)
    const [reducedTaxTotal, setReducedTaxTotal] = useState(0)
    const [specialTaxTotal, setSpecialTaxTotal] = useState(0)
    const [noTaxTotal, setNoTaxTotal] = useState(0)
    const [netTotal, setNetTotal] = useState(0)
    const [depositTotal, setDepositTotal] = useState(0)
    const [date, setDate] = useState([])
    const [loading, setLoading] = useState(false)

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const instance = urlParams.get('instance')
    const receiptId = urlParams.get('receipt')
    const storeUrlId = urlParams.get('store')
    const [receipt, setReceipts] = useState([]);
    useEffect(() => {
        getReceiptData();
        getStoreData();
        fetchProducts();
    }, [])

    let storeId = "";

    const setStoreId = () => {
        if (storeUrlId) {
            storeId = storeUrlId;
        }
        else if (instance === "toggenburgshop") {
             storeId = "wattwil";
        }
        else if (instance === "9620") {
            storeId = "WjwOirkTqfScgTPfEPA4";
        }
        else {
            storeId = "iJcJrTa0mJUfwFoVAFuq";
        }
        return storeId
    }

    const getReceiptData = async () => {
        const response = db.collection("instance").doc(instance).collection("orders").doc(receiptId)

        await response.get().then((docSnap) => {
            setReceipts(docSnap.data())
            return convertDate(docSnap.data().orderDate)
            }

        )
    }
    const getStoreData = async () => {
        const response = db.collection("instance").doc(instance).collection("stores").doc(setStoreId())

        await response.get().then((docSnap) => {
            return setStore(docSnap.data())
        }
        )
    }

    function addStandardTotal(productTax) {
        setStandardTaxTotal(prevState => prevState + (productTax / 100) )
    }
    function addReducedTotal(productTax) {
        setReducedTaxTotal(prevState => prevState + (productTax / 100) )
    }
    function addSpecialTotal(productTax) {
        setSpecialTaxTotal(prevState => prevState + (productTax / 100) )
    }
    function addNoTaxTotal(productTax) {
        setNoTaxTotal(prevState => prevState + (productTax / 100) )
    }
    function addNetTotal(netOrder) {
        setNetTotal(prevState => prevState + (netOrder / 100) )
    }
    function addDepositTotal(deposit) {
        setDepositTotal(prevState => prevState + (deposit / 100) )
    }
    function convertDate(date) {
        setDate({
            time: date.toDate().toLocaleTimeString('de'),
            oDate: date.toDate().toLocaleDateString('de')})
    }
    const print = () => {html2pdf(document.getElementById('element-to-print'))}
    const fetchProducts = async () => {
        setProducts([])
        setLoading(true)
        const response = db.collection("instance").doc(instance).collection('orders').doc(receiptId)
        await response.get().then((docSnap) => {
                // doc.data() is never undefined for query doc snapshots
                // console.log(doc.id, " => ", doc.data());
            docSnap.data().products.forEach(product => {
                addNetTotal(product.netPrice);
                addDepositTotal(product.deposit);
                switch (product.taxRate) {
                    case (0.077):
                        addStandardTotal(product.tax);
                        break;
                    case (0.025):
                        addReducedTotal(product.tax);
                        break;
                    case (0.037):
                        addSpecialTotal(product.tax);
                        break
                    case (0):
                        addNoTaxTotal(product.tax);
                        break;
                    default:
                        console.log("Error with Tax-Calculation")
                }
                if (product.type === "product" && product.title !== "Freieingabe Kunde" && product.supplier !== "") {
                    const supplier = product.supplier
                    db
                        .collection("instance")
                        .doc(instance)
                        .collection('supplier')
                        .doc(supplier).get().then((supplierSnap) => {
                        product.supplierName = supplierSnap.data().name
                        let newProducts = product
                        newProducts.supplierName = supplierSnap.data().name
                        return setProducts(products => [...products, newProducts])

                    });

                } else {
                    setProducts(products => [...products, product])
                }
            })
            })
            .then(() => {
                setLoading(false)
            })

    };

    const css = {
        fontSize: 12,
    }

    return (
        <>
        {loading ? (
                <Box sx={{ width: '100%', marginTop: 3, display: "flex", justifyContent: "center"}}>
                    <CircularProgress />
                </Box>
            ) : (
        <ThemeProvider theme={theme}>
            <Container display="flex" alignItems="center" id="element-to-print">
                <Box
                    sx={{
                        marginTop: 8,
                        marginBottom: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <img src={store.logoUrl} width="200" height="auto" />

                    <Typography>
                        {store.name}
                    </Typography>
                    <Typography>
                        {store.address}

                    </Typography>
                    <Typography>

                        {store.zipCode} {store.city}
                    </Typography>
                </Box>
            <TableContainer sx={{ fontSize: 11}}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontSize: css.fontSize}} >Produkte</TableCell>
                            <TableCell sx={{ fontSize: css.fontSize}} align="right">Anzahl</TableCell>
                            <TableCell sx={{ fontSize: css.fontSize}} align="right">MWST</TableCell>
                            <TableCell sx={{ fontSize: css.fontSize}} align="right">Betrag</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {products.map((products) => (
                            <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell sx={{ fontSize: css.fontSize}} component="th" scope="row">
                                    {products.title} <br />{store.showSupplier && <div>{products.supplierName &&  "Lieferant:"} {products.supplierName}</div>}
                                </TableCell>
                                <TableCell sx={{ fontSize: css.fontSize}} align="right">{products.quantity}</TableCell>
                                <TableCell sx={{ fontSize: css.fontSize}} align="right">{(products.taxRate * 100)}%</TableCell>

                                <TableCell sx={{ fontSize: css.fontSize}} align="right">{(products.price/100).toFixed(2)}</TableCell>
                            </TableRow>
                        ))}
                        <TableRow
                            sx={{ width: "auto" }}
                        >
                            <TableCell sx={{ fontSize: css.fontSize}} component="th" scope="row" >Zwischentotal</TableCell>
                            <TableCell > </TableCell>
                            <TableCell > </TableCell>
                            <TableCell sx={{ fontSize: css.fontSize}}  align="right" >{netTotal.toFixed(2)}</TableCell>
                        </TableRow>

                        {standardTaxTotal > 0 &&
                        <TableRow>
                            <TableCell sx={{ fontSize: css.fontSize}} >MWST 7.7%</TableCell>
                            <TableCell > </TableCell>
                            <TableCell > </TableCell>
                            <TableCell sx={{ fontSize: css.fontSize}} align="right"  >{standardTaxTotal.toFixed(2)}</TableCell>
                        </TableRow>
                        }
                        {reducedTaxTotal > 0 &&
                        <TableRow>
                            <TableCell sx={{ fontSize: css.fontSize}} >MWST 2.5%</TableCell>
                            <TableCell > </TableCell>
                            <TableCell > </TableCell>
                            <TableCell sx={{ fontSize: css.fontSize}} align="right" >{reducedTaxTotal.toFixed(2)}</TableCell>
                        </TableRow>
                        }
                        {specialTaxTotal > 0 &&
                        <TableRow>
                            <TableCell sx={{ fontSize: css.fontSize}} >MWST 3.7%</TableCell>
                            <TableCell > </TableCell>
                            <TableCell > </TableCell>
                            <TableCell sx={{ fontSize: css.fontSize}} align="right" >{specialTaxTotal.toFixed(2)}</TableCell>
                        </TableRow>
                        }
                        {noTaxTotal > 0 &&
                        <TableRow>
                            <TableCell sx={{ fontSize: css.fontSize}} >ohne MWST</TableCell>
                            <TableCell > </TableCell>
                            <TableCell > </TableCell>
                            <TableCell sx={{ fontSize: css.fontSize}} align="right" >{noTaxTotal.toFixed(2)}</TableCell>
                        </TableRow>
                        }
                        {depositTotal > 0 &&
                        <TableRow>
                            <TableCell sx={{ fontSize: css.fontSize}} >Depot</TableCell>
                            <TableCell > </TableCell>
                            <TableCell > </TableCell>
                            <TableCell sx={{ fontSize: css.fontSize}} align="right" >{(depositTotal).toFixed(2)}</TableCell>
                        </TableRow>
                        }
                        <TableRow>
                            <TableCell sx={{ fontSize: css.fontSize}} ><b>Total CHF</b></TableCell>
                            <TableCell > </TableCell>
                            <TableCell > </TableCell>
                            <TableCell sx={{ fontSize: css.fontSize}}  align="right" ><b>{(receipt.price / 100).toFixed(2)}</b></TableCell>
                        </TableRow>
                    </TableBody>

                </Table>
            </TableContainer>
                <Box
                    sx={{
                        paddingTop: 2,
                        paddingBottom: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: "center"
                    }}
                >
                    <Typography sx={{padding: 1, fontSize: 15}} component="p" variant="p">
                        {store.customMessage}
                    </Typography>
                    <Typography sx={{padding: 1, fontSize: 11}} component="p" variant="p">
                        {date.oDate}  {date.time}
                    </Typography>
                    <Typography sx={{padding: 1, fontSize: 11}} component="p" variant="p">
                        MWST-Nr. {store.vatNumber}
                    </Typography>
                    <Typography sx={{padding: 1, fontSize: 11}} component="p" variant="p">
                        Zahlmethode: {receipt.paymentMethod}
                    </Typography>
                    <Typography sx={{padding: 1, fontSize: 11}} component="p" variant="p">
                        Status: {receipt.paymentState}
                    </Typography>
                    <Typography sx={{padding: 1, fontSize: 11}} component="p" variant="p">
                        Bestellnummer: {receipt.orderId}
                    </Typography>
                </Box>
            </Container>
            <Container >
            <Box sx={{ minHeight: "150px", paddingBottom: "50px"}} textAlign='center'>
                <Button variant="contained" onClick={print}> Als PDF herunterladen</Button>
            </Box>
            </Container>

        </ThemeProvider>
            )}
        </>
            );
}

export default GetReceipt;

/* <PDFDownloadLink document={<GeneratePDF
                    date={date}
                    standardTaxTotal={standardTaxTotal}
                    reducedTaxTotal={reducedTaxTotal}
                    specialTaxTotal={specialTaxTotal}
                    depositTotal={depositTotal}
                    netTotal={netTotal}
                    store={store}
                    products={products}
                    receipt={receipt}
                />} fileName="somename.pdf">
                    {({ blob, url, loading, error }) =>
                        loading ? 'Loading document...' : 'Download now!'
                    }
                </PDFDownloadLink>
                */