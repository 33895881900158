// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import firebase from 'firebase';

export const firebaseConfig = {
    apiKey: "AIzaSyA6VBrUC0kjTNvBOyeOutw3RQD23jpDlao",
    authDomain: "scango-ch.firebaseapp.com",
    databaseURL: "https://scango-ch-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "scango-ch",
    storageBucket: "scango-ch.appspot.com",
    messagingSenderId: "842433807547",
    appId: "1:842433807547:web:eb2ccc8489c9e3a6cf159e",
    measurementId: "G-3ZQSQF40W6"
};
firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const db = firebase.firestore();



export default db;