import GetReceipt from "./GetReceipt";
import NoReceipt from "./noReceipt";
import React from "react";
import "./App.css";
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
export default class App extends React.Component {
    render() {
        return (
            <div>
                {urlParams.toString().length <= 0 &&
                <NoReceipt/>
                }

                {urlParams.toString().length > 0 &&
                <GetReceipt/>
                }
            </div>
        );
    }
}
