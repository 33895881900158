import React from 'react';
import {
    Document,
    Page,
    View,
    Text,
    Link,
    Font,
    StyleSheet, PDFDownloadLink,
} from '@react-pdf/renderer';
import {Image} from "@mui/icons-material";


const css = {
    fontSize: 12,
}

const styles = StyleSheet.create({
    title: {
        margin: 20,
        fontSize: 25,
        textAlign: 'center',
        backgroundColor: '#e4e4e4',
        textTransform: 'uppercase',
        fontFamily: 'Oswald',
    },
    body: {
        flexGrow: 1,
    },
    row: {
        flexGrow: 1,
        flexDirection: 'row',
    },
    block: {
        flexGrow: 1,
    },
    text: {
        width: '60%',
        margin: 10,
        fontFamily: 'Oswald',
        textAlign: 'justify',
    },
    fill1: {
        width: '40%',
        backgroundColor: '#e14427',
    },
    fill2: {
        flexGrow: 2,
        backgroundColor: '#e6672d',
    },
    fill3: {
        flexGrow: 2,
        backgroundColor: '#e78632',
    },
    fill4: {
        flexGrow: 2,
        backgroundColor: '#e29e37',
    },
});

Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});


export default (props) => (
    <Document>
        <Page size="A4">
                <View styles={styles.body}>
                    <View
                    >
                        <Image src={props.store.logoUrl}   />

                        <Text>
                            {props.store.name}
                        </Text>
                        <Text>
                            {props.store.address}

                        </Text>
                        <Text>

                            {props.store.zipCode} {props.store.city}
                        </Text>
                    </View>
                    <View>
                        <View >
                            <View>
                                <View>
                                    <Text style={styles.text} >Produkte</Text>
                                    <Text style={styles.text}>Anzahl</Text>
                                    <Text style={styles.text}>MWST</Text>
                                    <Text style={styles.text}>Betrag</Text>

                                </View>
                            </View>
                            <View>
                                {props.products.map((products) => (
                                    <View>
                                        <Text style={styles.text}>
                                            {products.title}
                                        </Text>
                                        <Text style={styles.text} >{products.quantity}</Text>
                                        <Text style={styles.text}>{(products.taxRate * 100)}%</Text>

                                        <Text style={styles.text}>{(products.price/100).toFixed(2)}</Text>
                                    </View>
                                ))}
                                <View>
                                    <Text style={styles.text} >Zwischentotal</Text>
                                    <View > </View>
                                    <View > </View>
                                    <Text style={styles.text}  >{props.netTotal.toFixed(2)}</Text>
                                </View>

                                {props.standardTaxTotal > 0 &&
                                <View>
                                    <Text style={styles.text} >MWST 7.7%</Text>
                                    <View > </View>
                                    <View > </View>
                                    <Text style={styles.text}  >{props.standardTaxTotal.toFixed(2)}</Text>
                                </View>
                                }
                                {props.reducedTaxTotal > 0 &&
                                <View>
                                    <Text style={styles.text} >MWST 2.5%</Text>
                                    <View > </View>
                                    <View > </View>
                                    <Text style={styles.text} >{props.reducedTaxTotal.toFixed(2)}</Text>
                                </View>
                                }
                                {props.specialTaxTotal > 0 &&
                                <View>
                                    <Text style={styles.text} >MWST 3.7%</Text>
                                    <View > </View>
                                    <View > </View>
                                    <Text style={styles.text} >{props.specialTaxTotal.toFixed(2)}</Text>
                                </View>
                                }
                                {props.depositTotal > 0 &&
                                <View>
                                    <Text style={styles.text} >Depot</Text>
                                    <View > </View>
                                    <View > </View>
                                    <Text style={styles.text} >{(props.depositTotal).toFixed(2)}</Text>
                                </View>
                                }
                                <View>
                                    <Text style={styles.text} ><b>Total CHF</b></Text>
                                    <View > </View>
                                    <View > </View>
                                    <Text style={styles.text}  ><b>{(props.receipt.price / 100).toFixed(2)}</b></Text>
                                </View>
                            </View>

                        </View>
                    </View>
                        <Text style={styles.text} >
                            Herzlichen Dank für Ihren Einkauf.
                        </Text>
                        <Text style={styles.text} >
                            {props.date.oDate}  {props.date.time}
                        </Text>
                        <Text style={styles.text} >
                            MWST-Nr. {props.store.vatNumber}
                        </Text>
                        <Text style={styles.text} >
                            Zahlmethode: {props.receipt.paymentMethod}
                        </Text>
                        <Text style={styles.text} >
                            Status: {props.receipt.paymentState}
                        </Text>
                        <Text style={styles.text} >
                            Bestellnummer: {props.receipt.orderId}
                        </Text>
                </View>
        </Page>
    </Document>
);